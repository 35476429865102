Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.userNameTitle = "User's Name";
exports.btnSubmitTitle = "Submit";
exports.signatureEndpoint = "bx_block_digital_signature/digital_signatures"
exports.postMethodType = "POST"
exports.emptyMessage = "Complete this field"
exports.Success = "Success" 
exports.Error = "Error"
exports.successMessage = "Signature submitted successfully !"
// Customizable Area End
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import SignatureCanvas from 'react-signature-canvas'
// Customizable Area End

export const configJSON = require("./config");

export interface IerrorObject
{
   [ObjectKey:string] : string[]
}
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  userName: string,
  signatureImage: string,
  shouldLoad: boolean,
  imageType: string,
  shouldScroll: boolean,
  showSignatureError: boolean,
  showUsernameError: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DigitalSignaturesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  signatureRefWeb: SignatureCanvas | null  = null
  postSignatureApiCallId:string | null = null
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      userName:"",
      signatureImage:"",
      shouldLoad:false,
      imageType:"png",
      shouldScroll: true,
      showSignatureError: false,
      showUsernameError: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

       const errorResponse=message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.postSignatureApiCallId) {
          if (!responseJson.data.attributes.errors) {
            this.signatureSuccessCallBack()
          } else {
            this.signatureFailureCallBack(responseJson.data.attributes.errors)
          }
        }
      }
      else
      {
        this.setState({
          shouldLoad:false
        })
        this.parseApiCatchErrorResponse(errorResponse)
      }
    }
    // Customizable Area End
  }

 
  // Customizable Area Start

  signatureSuccessCallBack=()=>
  {
    this.setState({
      shouldLoad:false,
      signatureImage:"",
      userName:""
    })

    if(this.signatureRefWeb)
    {
      this.signatureRefWeb.clear()
    }
    else
    {
      return false
    }
    setTimeout(() => {
      this.showAlert(configJSON.Success,configJSON.successMessage)
    }, 500);
  }
  signatureFailureCallBack=(error:IerrorObject)=>
  {
    this.setState({
      shouldLoad:false,
      signatureImage:"",
      userName:"",
    })
    const errorText:string = Object.values(error)[0][0]

    if(this.signatureRefWeb)
    {
      this.signatureRefWeb.clear()
    }
    else
    {
      return false
    }
    setTimeout(() => {
      this.showAlert(configJSON.Error,errorText)
    }, 500);

  }
  
  saveSignatureWeb=()=>
  {
    const dataUrl=(this.signatureRefWeb?.isEmpty())?"":this.signatureRefWeb?.toDataURL()
    this.setState({
      signatureImage: dataUrl==undefined?"":dataUrl
    },()=>
    {
      this.submitImage()
    })
  }

  submitImage=async()=>
  {

    this.setState({
      showSignatureError:false,
      showUsernameError:false,
    })

    if(this.isStringNullOrBlank(this.state.signatureImage))
    {
      this.setState({showSignatureError:true})
      return false
    }

    if(this.isStringNullOrBlank(this.state.userName))
    {
      this.setState({showUsernameError:true})
      return false
    }
  
    const header = {
      "Content-Type": configJSON.apiContentType
    };

    const httpBody = {
      digital_signature: {
        username: this.state.userName,
        signature_image: this.state.signatureImage
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postSignatureApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signatureEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethodType
    );

    this.setState({ shouldLoad: true })

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  changeUserName=(userName:string)=>
  {
    this.setState({
      userName:userName
    })
  }

  isStringNullOrBlank(stringValue: string) {
    return stringValue === null || stringValue.trim().length === 0;
  }

  // Customizable Area End
}

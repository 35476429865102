import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";
// Customizable Area Start
import {createTheme, ThemeProvider } from "@material-ui/core/styles";
import Signaturecanvas from 'react-signature-canvas'
// Customizable Area End

import DigitalSignaturesController, {
  Props,
  configJSON,
} from "./DigitalSignaturesController.web";

export default class DigitalSignatures extends DigitalSignaturesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
       <ThemeProvider theme={theme}>
          <Container style={{display:"flex",alignItems:"center",flex:1,flexDirection:"column"}}>
              <Box style={styles.boxContainer}>
              <Signaturecanvas
              ref={(reference:Signaturecanvas)=>this.signatureRefWeb=reference}
              canvasProps={{ style:{height:"100%",width:"100%"}}}
              backgroundColor={"#ebebeb"}
              />
              </Box>
              {this.state.showSignatureError &&
              <Typography style={styles.errorMessage}>{configJSON.emptyMessage}</Typography>}
              <Input 
              id={"txt_userName"}
              onChange={(event)=>this.changeUserName(event.target.value)}
              fullWidth={true}
              style={{padding:20,fontSize:20,width:"70vw",fontWeight:"bold"}}
              value={this.state.userName}
            />
            {this.state.showUsernameError && 
            <Typography style={styles.errorMessage}>{configJSON.emptyMessage}</Typography>}
              <Button 
              id={"btn_submit"}
              onClick={()=>this.saveSignatureWeb()}
              size={"small"}
              title={"submit"}
              style={styles.btnSubmit}>
                Submit
              </Button>
          </Container>
        </ThemeProvider>
      
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const styles={
  Container:
  {
    flex:1,
    display:"flex",
    flexDirection:"column"
  },
  boxContainer:
  {
    height:"70vh",
    width:"70vw",
    overflow:"hidden",
    alignSelf:"center",
    margin:20
  },
  errorMessage:
  {
   
    color:"red",
    fontSize:20,
    width:"70vw"
  },
  inputUsrName:
  {
    height:100,
    width:"100%",
    fontWeight:"bold"
  },
  btnSubmit:
 {
  height:"10vh",
  width:'20vh',
  backgroundColor:"#0061A7",
  color:"fff",
  margin:20,
  alignSelf:"center"
},
  
}
// Customizable Area End
// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import CartList from '../../blocks/PricingEngine2/src/CartList.web';
import PricingEngine2 from '../../blocks/PricingEngine2/src/PricingEngine2.web';
import Prioritise from '../../blocks/Prioritise/src/Prioritise.web';
import './App.css'; 
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";
import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import DigitalSignatures from '../../blocks/DigitalSignatures/src/DigitalSignatures.web'

const routeMap = {

  Home: {
    component: DigitalSignatures,
    path: '/',
    exact: true
  },
  
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  DigitalSignatures: {
    component: DigitalSignatures,
    path: '/DigitalSignatures',
    exact: true
  },

  PricingEngine2: {
    component: PricingEngine2,
    path: '/PricingEngine2',
  },

  CartList: {
    component: CartList,
    path: '/CartList',
  },

  Prioritise: {
    component: Prioritise,
    path: '/Prioritise',
  }
};

class App extends Component {
  render() {
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
